import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './App.scss';

function App() {
    return (
        <div className="App" data-testid="App">
            <header className="App-header">
                <Logo className="App-logo"/>
            </header>
        </div>
    );
}

export default App;
